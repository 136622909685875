.layout {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.sidebar {
  width: var(--sidebar-width);
  background: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.content {
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 0px;
  padding-top: 25px;
  overflow-y: scroll;
}

.sidebar-button {
  outline: none;
  border: 0;
  box-sizing: border-box;
  display: flex;
  padding: 20px;
  gap: 10px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 5px 0px 0px 5px;
  border-right: 5px solid #dadada;
}

.sidebar-button:hover {
  background-color: #f9f9f9;
}
.sidebar-button.green:hover,
.sidebar-button.green.active {
  border-right: 5px solid var(--green);
  color: var(--green);
}

.sidebar-button.blue:hover,
.sidebar-button.blue.active {
  border-right: 5px solid var(--blue);
  color: var(--blue);
}
