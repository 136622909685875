body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.view {
  height: calc(100vh - 50px);
  background: #f7f7f7;
}

:root {
  --sidebar-width: 200px;
  --green: #40a90e;
  --blue: #1281ca;
}
